@import './variables.css';
/* rem and em do NOT depend on html font-size in media queries! */

/* 1 rem = 1em = 16px */

/* +++++++++++++++++++++++++++++++++ */
/* ABOVE 2000px ULTRA WIDE */
/* +++++++++++++++++++++++++++++++++ */

@media (min-width: 125em) {
  .header {
    justify-content: space-around;
  }
}

/* +++++++++++++++++++++++++++++++++ */
/* BELOW 1344px (Smaller Desktops) */
/* +++++++++++++++++++++++++++++++++ */
@media (max-width: 84em) {
  .hero {
    max-width: 120rem;
  }

  .heading-primary {
    font-size: 4.4rem;
  }
}

/* +++++++++++++++++++++++++++++++++ */
/* BELOW 1200px (Landscape Tablets) */
/* +++++++++++++++++++++++++++++++++ */

@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }

  .grid {
    column-gap: 4.8rem;
    row-gap: 6.4rem;
  }

  .header {
    padding: 0 3.2rem;
  }
  .heading-secondary {
    font-size: 3.6rem;
  }

  .heading-tertiary {
    font-size: 2.4rem;
  }

  .main-nav-list {
    gap: 3.2rem;
  }
  .hero {
    gap: 4.8rem;
  }

  .team-box {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 4.8rem;
    justify-items: center;
  }

  .grid-memory {
    grid-template-columns: repeat(4, 1fr);
  }
}

/* +++++++++++++++++++++++++++++++++ */
/* BELOW 944px (Tablets) */
/* +++++++++++++++++++++++++++++++++ */

@media (max-width: 59em) {
  /* GENERAL */
  html {
    /* 8px / 16px = 0.5 = 50% */
    font-size: 50%;
  }

  .header {
    padding: 0px 3.2rem;
  }

  .container {
    padding: 0 4.8rem;
  }

  /* MOBILE NAV*/
  .btn-mobile-nav {
    display: block;
  }

  .main-nav {
    background-color: var(--color-primary);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 4.8rem;

    display: none;
  }

  .main-nav-link {
    border-bottom: 0.4rem solid var(--color--logo-icon-primary);
  }

  .main-nav-link.nav-cta:link,
  .main-nav-link.nav-cta:visited {
    border-bottom: none;
    padding: 1.2rem 1.8rem;
  }

  .nav-open .main-nav {
    animation: append-animate 0.75s ease-in;
  }

  .nav-open .btn-mobile-nav {
    z-index: 2;
  }

  .nav-open .main-nav {
    display: flex;
    opacity: 0.95;
    z-index: 1;
  }

  .nav-open .icon-close {
    display: block;
  }

  .nav-open .icon-menu {
    display: none;
  }

  .main-nav-list {
    flex-direction: column;
    gap: 4.8rem;
    border-bottom: none;
  }

  .main-nav-link:link,
  .main-nav-link:visited {
    font-size: 3rem;
  }

  /* HOME PAGE */

  .hero {
    grid-template-columns: 1fr;
    padding: 0 8rem;
    row-gap: 4.8rem;
  }

  .hero-text-box {
    text-align: center;
  }

  .hero-img-box {
    text-align: center;
  }
  .hero-img {
    width: 60%;
  }

  .pricing-box {
    grid-template-columns: repeat(2, 1fr);
  }

  .pricing-box .pricing-option:last-child {
    grid-column: 1/-1;
  }

  .feature-box {
    grid-template-columns: repeat(2, 1fr);
    justify-items: center;
  }

  .feature-box .feature:last-child {
    grid-column: 1/-1;
  }

  /* ABOUT PAGE */

  .step-number {
    font-size: 7.4rem;
  }

  /* FOOD PAGE */
  .snacks-box {
    grid-template-columns: repeat(4, 1fr);
  }

  /* FAQ PAGE */
  .faq-grid {
    grid-template-columns: 1fr;
  }
  .accordion-item {
    row-gap: 1.6rem;
  }
}

/* +++++++++++++++++++++++++++++++++ */
/* BELOW 800px  */
/* +++++++++++++++++++++++++++++++++ */

@media (max-width: 50em) {
  .heading-secondary {
    margin-bottom: 4.8rem;
  }

  .grid--footer {
    grid-template-columns: repeat(3, 1fr);
  }

  .flex--row {
    flex-direction: column;
  }

  .essential-text-box {
    order: 1;
    max-width: 65%;
    min-width: 37rem;
  }
  .essential-img-box {
    max-width: 60%;
    order: 0;
  }

  .logo-col {
    grid-column: span 3;
  }

  .nav-col {
    grid-row: 1;
  }

  .carousel {
    width: 64rem;
  }

  /* TEAM PAGE */
  .team-box {
    grid-template-columns: 1fr;
  }

  /* FOOD PAGE */
  .meal-day-name {
    margin-left: 4.2rem;
  }

  .meals-day-row {
    grid-template-columns: 1fr;
    row-gap: 3.2rem;
    margin-bottom: 0;
  }

  .meal-day-group {
    display: flex;
    justify-content: center;
  }
  .meal-day-group:last-child {
    margin-bottom: 0;
  }

  .meal-titles-box {
    grid-template-columns: 1fr;
    place-items: center;
  }

  .meal-title {
    display: inline-block;
    font-size: 3rem;
    writing-mode: vertical-rl;
    text-orientation: mixed;
    transform: rotate(180deg);
    margin-bottom: 0;
    margin-right: 2rem;
  }

  .meal-title:nth-child(2) {
    margin-bottom: 6rem;
  }
  .meal-title:last-child {
    /* display: block; */
    margin-bottom: 3.2rem;
  }

  .snacks-box {
    grid-template-columns: repeat(3, 1fr);
  }

  .boxes-info {
    font-size: 1.8rem;
  }

  /* ABOUT PAGE*/
  .step-number {
    font-size: 6.2rem;
  }

  /* DRINKS PAGE*/
  .section-non-alcohlic .grid--3-cols {
    grid-template-columns: 1fr 1fr;
  }

  .section-water .container,
  .section-coffee .container {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .water-box,
  .coffee-box {
    grid-template-columns: 1fr;
    max-width: 55rem;
    row-gap: 0;
  }

  .water-text-box {
    order: 1;
  }

  .water-img,
  .coffee-img {
    width: 100%;
    height: 100%;
  }

  .water-text-box,
  .coffee-text-box {
    padding: 3.4rem;
  }

  .alcohlic-box {
    grid-template-columns: 1fr;
  }

  .alcohlic-text-box {
    align-items: center;
  }

  .alcohlic-info {
    text-align: center;
  }

  .alcohlic-img-box {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .alcohlic-img {
    max-width: 50rem;
  }
}

/* +++++++++++++++++++++++++++++++++ */
/* BELOW 752px  */
/* +++++++++++++++++++++++++++++++++ */

@media (max-width: 47em) {
  /* DRINKS PAGE*/

  .drink-choices {
    margin-bottom: 6.4rem;
  }
  .drink-grid {
    grid-template-columns: 1fr;
    row-gap: 4.8rem;
    justify-items: center;
  }

  .drink-box {
    max-width: 55rem;
  }

  /* ABOUT PAGE*/
  .about {
    grid-template-columns: 1fr;
    row-gap: 4.8rem;
  }

  .about-img-box:nth-child(3) {
    grid-row: 4;
  }

  /* ABOUT PAGE*/

  .grid--steps {
    grid-template-columns: 1fr;
  }

  .step-img-box {
    justify-self: center;
    max-width: 55rem;
  }

  .section-location .grid--2-cols {
    grid-template-columns: 1fr;
  }

  .location-img-box {
    max-width: 55rem;
    justify-self: center;
  }
}

/* +++++++++++++++++++++++++++++++++ */
/* BELOW 640px  */
/* +++++++++++++++++++++++++++++++++ */

@media (max-width: 40em) {
  /* HOME PAGE */
  .carousel {
    width: 80%;
    flex-direction: column;
    padding: 0;
    gap: 3.6em;
    /* background-color: transparent; */
    background: var(--linear-gradient-primary--test);
  }

  .testimonial {
    padding: 3.2rem;
    text-align: center;
  }

  .btn--carousel {
    background-color: #eb616b;
  }

  .pricing-box {
    grid-template-columns: 1fr;
  }
  .feature-box {
    grid-template-columns: 1fr;
  }

  .feature-heading {
    justify-content: flex-start;
  }

  .feature-icon {
    margin-left: 6rem;
  }

  .feature {
    max-width: 33.6rem;
  }

  .gallery-grid {
    grid-template-columns: 1fr;
  }

  .gallery {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .gallery .img-border {
    width: 90%;
    min-width: 33.6rem;
  }

  /* ACTIVITIES PAGE*/

  .intro {
    grid-template-columns: 1fr;
    text-align: center;
    gap: 3.6rem;
  }

  .intro-description {
    margin-bottom: 2.4rem;
  }

  .activity-grid {
    grid-template-columns: 1fr;
  }

  .activity-info {
    font-size: 2rem;
  }

  .activity-box-inner {
    width: 100%;
  }

  /* FOOD PAGE*/

  .snacks-box {
    grid-template-columns: 1fr 1fr;
  }

  /* ACTIVITIES PAGE*/
}

/* +++++++++++++++++++++++++++++++++ */
/* BELOW 544px (PHONES) */
/* +++++++++++++++++++++++++++++++++ */

@media (max-width: 34em) {
  /* COMPONENTS */
  .section-intro {
    padding: 2.4rem 0 6.4rem 0;
  }

  .intro {
    row-gap: 1.4rem;
  }

  .intro-boost {
    margin-bottom: 1.6rem;
  }

  /* GENERAL */
  .heading-secondary,
  .margin-bottom--sm {
    margin-bottom: 3.6rem !important;
  }

  .container {
    padding: 0 3.6rem;
  }

  /* HOME PAGE */
  .hero {
    padding: 0 3.2rem;
  }

  .section-hero {
    padding: 2.4rem 0 6.4rem 0;
  }

  .hero-img {
    width: 80%;
  }

  .section-gallery {
    padding: 8rem 0;
  }

  /* DRINKS PAGE*/

  .section-alcohlic .subheading,
  .section-alcohlic .heading-secondary {
    text-align: start;
  }

  .boxes-grid.grid--3-cols {
    grid-template-columns: 1fr;
  }

  .alcohlic-box {
    row-gap: 3.2rem;
  }
  .alcohlic-text-box {
    padding: 0;
  }

  .alcohlic-info {
    text-align: start;
  }

  .drink-choices {
    margin-bottom: 3.2rem;
  }
  .alcohlic-warning {
    margin-top: 3.2rem;
  }

  /* FOOD PAGE*/

  .snacks-box {
    grid-template-columns: 1fr;
  }

  /* TEAM PAGE */

  .section-team-about {
    padding: 2.4rem 0 6.4rem 0;
  }

  /* ABOUT PAGE */
  .about-boost {
    margin-bottom: 1.6rem;
  }

  .section-how {
    padding: 2.4rem 0 6.4rem 0;
  }

  .section-location {
    padding: 4.8rem 0 4.8rem 0;
  }

  .grid-memory {
    grid-template-columns: repeat(2, 1fr);
  }
}

/* +++++++++++++++++++++++++++++++++ */
/* BELOW 330px (SMALL PHONES) */
/* +++++++++++++++++++++++++++++++++ */

@media (max-width: 21em) {
  /* GENERAL */
  html {
    /* 8px / 16px = 0.5 = 50% */
    font-size: 43.75%;
  }
}

/*
-FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98


SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128
*/
